<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>
    <XDesk
      :details="xdeskUser"
      :is-admin="false"
      secret-key="secret-gtwln1pww2h8b700zbd103t14"
      :set-loader="setLoading"
    >
      <div class="btn btn-danger">
        {{ !isLoading ? "Please Wait" : "Open Ticket" }}
      </div>
    </XDesk>
    <b-navbar-nav class="nav align-items-center ml-auto">

      <template
        v-if="
          $permissionAbility(
            permissionsConstant.ATTENDANCE_CHECK_IN_OUT,
            permissions
          )
        "
      >
        <attendance-check-in-out />
      </template>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user?.name }}
            </p>

          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="user?.avatar || avatar"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click.prevent="
            () =>
              $route.name != 'user-profile'
                ? $router.push({ name: 'user-profile' })
                : null
          "
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>
        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click.prevent="onLogout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { permissionsConstant } from '@/helpers/permissionsConstant'
import XDesk from '@/views/admin/ticket/XDesk.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    // Navbar Components
    DarkToggler,
    XDesk,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      permissionsConstant,
      isLoading: false,
      xdeskUser:{
        name: "LeadSpiderCustomer",
        email: "customer@leadspider.net",
      }, 
    }
  },
  computed: {
    ...mapGetters({
      user: 'userModule/getUser',
      permissions: 'userModule/getPermissions',
    }),
    avatar() {
      return require('@/assets/images/avatars/avatar.webp')
    },
  },
  created() {
    this.isLoading = true;
    this.xdeskUser = {
      name: this.user.name,
      email: this.user.email
    }
  },
  methods: {
    async setLoading(status) {
      this.loading = status
    },
    async onLogout() {
      try {
        await this.$api.post('api/logout')

        await this.$store.dispatch('authModule/setIsAuthenticated', {
          isAuthenticated: false,
          token: null,
        })

        await this.$store.dispatch('authModule/clearPersistedState')

        await this.$store.dispatch('userModule/removeUser')

        await this.$store.dispatch('userModule/setPermissions', {
          permissions: null,
        })

        this.$router.replace({ name: 'login' })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${error?.response?.data?.message}`,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>
<style>
.main-menu.menu-light .navigation > li.active > a {
  color: #0c141c;
}
</style>
