var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "navbar-container d-flex content align-items-center"
  }, [_c('ul', {
    staticClass: "nav navbar-nav d-xl-none"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('b-link', {
    staticClass: "nav-link",
    on: {
      "click": _vm.toggleVerticalMenuActive
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MenuIcon",
      "size": "21"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
  }, [_c('dark-Toggler', {
    staticClass: "d-none d-lg-block"
  })], 1), _c('XDesk', {
    attrs: {
      "details": _vm.xdeskUser,
      "is-admin": false,
      "secret-key": "secret-gtwln1pww2h8b700zbd103t14",
      "set-loader": _vm.setLoading
    }
  }, [_c('div', {
    staticClass: "btn btn-danger"
  }, [_vm._v(" " + _vm._s(!_vm.isLoading ? "Please Wait" : "Open Ticket") + " ")])]), _c('b-navbar-nav', {
    staticClass: "nav align-items-center ml-auto"
  }, [_vm.$permissionAbility(_vm.permissionsConstant.ATTENDANCE_CHECK_IN_OUT, _vm.permissions) ? [_c('attendance-check-in-out')] : _vm._e(), _c('b-nav-item-dropdown', {
    staticClass: "dropdown-user",
    attrs: {
      "right": "",
      "toggle-class": "d-flex align-items-center dropdown-user-link"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        var _vm$user, _vm$user2;
        return [_c('div', {
          staticClass: "d-sm-flex d-none user-nav"
        }, [_c('p', {
          staticClass: "user-name font-weight-bolder mb-0"
        }, [_vm._v(" " + _vm._s((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.name) + " ")])]), _c('b-avatar', {
          staticClass: "badge-minimal",
          attrs: {
            "size": "40",
            "variant": "light-primary",
            "badge": "",
            "src": ((_vm$user2 = _vm.user) === null || _vm$user2 === void 0 ? void 0 : _vm$user2.avatar) || _vm.avatar,
            "badge-variant": "success"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "link-class": "d-flex align-items-center"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return function () {
          return _vm.$route.name != 'user-profile' ? _vm.$router.push({
            name: 'user-profile'
          }) : null;
        }($event);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "size": "16",
      "icon": "UserIcon"
    }
  }), _c('span', [_vm._v("Profile")])], 1), _c('b-dropdown-divider'), _c('b-dropdown-item', {
    attrs: {
      "link-class": "d-flex align-items-center"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onLogout($event);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "size": "16",
      "icon": "LogOutIcon"
    }
  }), _c('span', [_vm._v("Logout")])], 1)], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }